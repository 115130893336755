<template>
  <div class="password">
    <div class="content">
      <div class="item">
        <h3 class="password-title">
          {{ $t('password_title') }}
        </h3>
      </div>
      <div class="item">
        <mt-field
            :placeholder="$t('phonePlaceholder')"
            v-model="form.phone"
            type="tel"
            :v-mask="'#### #### ###'"
            :state="phoneState"
        ></mt-field>
      </div>
      <div class="item">
        <mt-field
          :placeholder="$t('passwordPlaceholder')"
          type="number"
          v-model="form.password"
          :attr="{ maxlength: 4 }"
          :state="pinState"
        >
          <img v-if="!visible" class="visible" src="../../assets/notVisible.png" height="20px" @click="visible = true">
          <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="visible = false">
        </mt-field>
      </div>
      <div class="item">
        <mt-field
          :placeholder="$t('confirmPasswordPlaceholder')"
          type="number"
          v-model="form.comfirmPassword"
          :attr="{ maxlength: 4 }"
          :state="comfirmPinState"
        >
          <img v-if="!newVisible" class="visible" src="../../assets/notVisible.png" height="20px" @click="newVisible = true">
          <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="newVisible = false">
        </mt-field>
      </div>
      <div class="item">
        <mt-button class="next-btn" size="large" @click="submit">{{
            $t("next")
          }}
        </mt-button>
      </div>
    </div>
    <get-code ref="getCode" :phone="form.phone" type="MODIFY_PASSWORD" @submit="getCodeRes" />
  </div>
</template>

<script>
import {
  phoneRules,
  validateCodeRules,
  pinRules,
  comfirmPinRules
} from "../../utils/rules";
import { mapState, mapMutations } from "vuex";
import { modifyPin } from '../../utils/api'
import getCode from '@/components/getCode.vue'
import mixin from './mixin'
export default {
  name: 'ModifyPin',
  components: {
    getCode,
  },
  mixins: [mixin],
  props: ['value'],
  data() {
    return {
      visible: false,
      newVisible: false,
    }
  },
  computed: {
    ...mapState(["record"]),
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "passwordPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    pinState() {
      this.SET_RECORD_ITEM({
        key: "passwordPassword",
        value: this.form.password,
      });
      return this.isCheck ? pinRules(this.form.password) : null;
    },
    comfirmPinState() {
      this.SET_RECORD_ITEM({
        key: "comfirmPasswordPassword",
        value: this.form.comfirmPassword,
      });
      return this.isCheck ? comfirmPinRules(this.form.comfirmPassword, this.form.password) : null;
    }
  },
  created() {
    this.form.phone = this.record.passwordPhone || "";
    this.form.password = this.record.passwordPassword || "";
    this.form.password = this.record.comfirmPasswordPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM"]),
    onRegister() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/register');
    },
    submit() {
      this.isCheck = true;
      var error = this.validation(['phone', 'pin', 'comfirmPin']);
      if (error) return this.$toast(error);
      this.$refs.getCode.popup(true)
    },
    async changePwd(){
      var error = this.validation(['phone', 'validateCode', 'pin', 'comfirmPin']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      await this.$axios({
        method: "post",
        url: modifyPin,
        data: {
          phone: this.form.phone.replace(/\s/g, ""),
          pin: this.form.password,
          confirmPin : this.form.comfirmPassword,
          validateCode: this.form.validateCode,
          isValidateCode: 'Y',
          deviceId: "H5",
          device: "H5",
        },
      })
          .then(async (e) => {
            if (e.status.code == "000") {
              this.SET_RECORD_ITEM({
                key: "loginPhone",
                value: this.form.phone,
              });
              this.SET_RECORD_ITEM({
                key: "loginPassword",
                value: this.form.password,
              });
              this.$router.back(-1)
            }else if (e.status.code == "993"){
              this.$toast(e.status.msg)
              this.onRegister()
            } else {
              this.$toast(e.status.msg)
            }
          })
          .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
    },
    async getCodeRes(data) {
      this.form.validateCode = data.validateCode;
      this.$refs.getCode.popup(false)
      await this.changePwd();
    },
  }
}
</script>

<style lang="scss" scoped>
.password {
  position: relative;
  min-height: 100vh;
  background-image: url('../../assets/login_bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  /deep/.mint-header{
    background-color: transparent;
    border-bottom: transparent;
  }
  .content {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    padding-right: 5vw;
    padding-left: 5vw;
    .item {
      margin-top: 25px;
      .mint-button{
        height:55px;
        border-radius: 50px;
        font-weight: 600;
        background: linear-gradient(#BB97FA, #855EF4);
        color: #FFFFFF;
        border:1px solid #FFFFFF;
      }
      .mint-cell {
        background-color: #f3effe;
        border-radius: 50px;
        /deep/ .mint-cell-wrapper {
          background: none;

        }
        /deep/ input {
          background-color: #f3effe;
        }
        /deep/ .mint-field-core {
          background-color: #f3effe;
          text-align: center;
          caret-color: #855ef4;
          color: #a5a4a6;
          font-size: 20px;
        }
        /deep/ input::placeholder{
          color: #999999;
          font-size: 16px;
        }
      }
      .password-title{
        font-size: 22pt;
        text-align: center;
        margin-bottom: 40px;
      }
      .next-btn{
        margin-top: 60px;
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
      }
      .code {
        display: flex;
        .mint-field {
          flex: 1;
        }
      }
    }
  }
  .ivr {
    color: #1D1D1D;
    margin: 10px 15px 0px 15px;
    .mint-button {
      color: #1D1D1D;
      border-color: #1D1D1D;
    }
  }
  .bottom {
    padding: 40px 10px 20px 10px;
    .mint-button {
      border-radius: 6px;
      font-size: 16px;
      background-color: #4F9B41;
      &:last-child {
        margin-top: 10px;
      }
    }
  }
  .visible {
    margin-left: 5px;
  }
}
</style>